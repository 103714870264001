import { createSelector } from 'reselect';

import { dataDepreciationFilteredByKiList } from '../../domain/depreciation/dataDepreciationFilteredByKiList';
import { dataDepreciationFilteredByKiTagsList } from '../../domain/depreciation/dataDepreciationFilteredByKiTagsList';
import { dataDepreciationFilteredByName } from '../../domain/depreciation/dataDepreciationFilteredByName';
import { dataDepreciationFilteredByNumber } from '../../domain/depreciation/dataDepreciationFilteredByNumber';
import { dataDepreciationHiddenByField } from '../../domain/depreciation/dataDepreciationHiddenByField';
import { dataDepreciationSwapColumnsOrder } from '../../domain/depreciation/dataDepreciationSwapColumnsOrder';
import { sortDepreciation } from '../../domain/depreciation/sortDepreciation';
import {
  DataDepreciationResponse,
  DataDepreciationSearchParams,
} from '../../domain/depreciation/types';
import { LoadingState } from '../../domain/schemas';

import {
  depreciationColumnsOrderSelector,
  depreciationFieldsFilterSelector,
  depreciationKiListFilterSelector,
  depreciationKiTagListSelector,
  depreciationNameFilterSelector,
  depreciationOrderSelector,
  depreciationSortSelector,
  depreciationSubMenuNumberFilterSelector,
} from '../ui/depreciationSetting';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_DEPRECIATION = 'FETCH_DATA_DEPRECIATION' as const;
const FETCH_DATA_DEPRECIATION_REQUEST = 'FETCH_DATA_DEPRECIATION_REQUEST' as const;
const FETCH_DATA_DEPRECIATION_SUCCESS = 'FETCH_DATA_DEPRECIATION_SUCCESS' as const;
const RENEW_DATA_DEPRECIATION = 'RENEW_DATA_DEPRECIATION' as const;

export const DataDepreciationActionTypes = {
  FETCH_DATA_DEPRECIATION,
  FETCH_DATA_DEPRECIATION_REQUEST,
  FETCH_DATA_DEPRECIATION_SUCCESS,
  RENEW_DATA_DEPRECIATION,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataDepreciationAction(params?: DataDepreciationSearchParams) {
  return {
    type: FETCH_DATA_DEPRECIATION,
    payload: { params },
  };
}

function fetchDataDepreciationRequestAction() {
  return {
    type: FETCH_DATA_DEPRECIATION_REQUEST,
  };
}

function fetchDataDepreciationSuccessAction(
  dataDepreciation: DataDepreciationResponse
) {
  return {
    type: FETCH_DATA_DEPRECIATION_SUCCESS,
    payload: { dataDepreciation },
  };
}

function renewDataDepreciationAction() {
  return {
    type: RENEW_DATA_DEPRECIATION,
  };
}

export const DataDepreciationActionCreators = {
  fetchDataDepreciationAction,
  fetchDataDepreciationRequestAction,
  fetchDataDepreciationSuccessAction,
  renewDataDepreciationAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataDepreciationAction = ReturnType<
  typeof fetchDataDepreciationAction
>;
type FetchDataDepreciationRequestAction = ReturnType<
  typeof fetchDataDepreciationRequestAction
>;
type FetchDataDepreciationSuccessAction = ReturnType<
  typeof fetchDataDepreciationSuccessAction
>;
type RenewDataDepreciationAction = ReturnType<
  typeof renewDataDepreciationAction
>;

type DataDepreciationAction =
  | FetchDataDepreciationAction
  | FetchDataDepreciationRequestAction
  | FetchDataDepreciationSuccessAction
  | RenewDataDepreciationAction;

/* ---------------------------------------------------------------
 * State
 */

type DataDepreciationState = {
  loadingState: LoadingState;
  dataDepreciation?: DataDepreciationResponse;
};

const initialState: DataDepreciationState = {
  loadingState: 'prepare',
  dataDepreciation: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別償却] 全ての状態を取得する
 */
function dataDepreciationSelector(rootState: {
  dataDepreciation: DataDepreciationState;
}) {
  return rootState.dataDepreciation;
}

/**
 * [機種別償却] データのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataDepreciationLoadingStateSelector = createSelector(
  dataDepreciationSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別償却] データを取得する
 * @returns データ
 */
export const dataDepreciationDataSelector = createSelector(
  dataDepreciationSelector,
  ({ dataDepreciation }) => dataDepreciation
);

/**
 * [機種別償却] ソート処理によって整形されたデータ (ソートラベル・サブメニュー時)
 *
 */
const dataDepreciationSortedSelector = createSelector(
  [
    dataDepreciationDataSelector,
    depreciationSortSelector,
    depreciationOrderSelector,
  ],
  (dataDepreciationData, depreciationSort, depreciationOrder) => {
    if (
      depreciationSort == null ||
      depreciationOrder == null ||
      dataDepreciationData == null
    ) {
      return dataDepreciationData;
    }

    const sortedDataDepreciation = sortDepreciation(
      dataDepreciationData,
      depreciationOrder,
      depreciationSort
    );
    return sortedDataDepreciation;
  }
);

/**
 * [機種別償却] フィルターによって行がフィルタリングされたデータ
 */
const dataRowsFilteredSelector = createSelector(
  [
    dataDepreciationSortedSelector,
    depreciationNameFilterSelector,
    depreciationSubMenuNumberFilterSelector,
    depreciationKiTagListSelector,
    depreciationKiListFilterSelector,
  ],
  (dataDepreciation, nameFilter, numberFilter, kiTagList, kiListFilter) => {
    if (dataDepreciation == null) {
      return dataDepreciation;
    }

    const filteredByName = dataDepreciationFilteredByName(
      dataDepreciation,
      nameFilter
    );
    const filteredByNameAndNumber = dataDepreciationFilteredByNumber(
      filteredByName,
      numberFilter
    );
    const filteredByNameAndNumberAndKiList = dataDepreciationFilteredByKiList(
      filteredByNameAndNumber,
      kiListFilter
    );

    const filteredByNameAndNumberAndKiListAndKiTagList = dataDepreciationFilteredByKiTagsList(
      filteredByNameAndNumberAndKiList,
      kiTagList
    );

    return filteredByNameAndNumberAndKiListAndKiTagList;
  }
);

/**
 * [機種別償却] 通常は使用しません dataDepreciationResultSelector  を使用してください
 *
 * 表示項目の新規項目追加時に、columnsOrderの更新が必要になりますが
 * 一度もDnDをしていない場合にはcolumnsOrderが存在していないため実テーブルのデータを使用します
 * そのため通常は使用しません。最終的に表示させたいデータを取得したい場合にはdataDepreciationResultSelectorを使用してください
 */
export const dataColumnsSwappedSelector = createSelector(
  [dataRowsFilteredSelector, depreciationColumnsOrderSelector],
  (filteredDataDepreciation, columnsOrder) => {
    if (filteredDataDepreciation == null) {
      return filteredDataDepreciation;
    }

    const swappedFieldsOrder = dataDepreciationSwapColumnsOrder(
      filteredDataDepreciation,
      columnsOrder
    );

    return swappedFieldsOrder;
  }
);

/**
 * [機種別償却] フィルターやスワップ、列非表示によって整形されたデータ
 */
export const dataDepreciationResultSelector = createSelector(
  [dataColumnsSwappedSelector, depreciationFieldsFilterSelector],
  (swappedDataDepreciation, fieldsFilter) => {
    if (swappedDataDepreciation == null) {
      return swappedDataDepreciation;
    }

    const hiddenByField = dataDepreciationHiddenByField(
      swappedDataDepreciation,
      fieldsFilter
    );

    return hiddenByField;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataDepreciationReducer(
  state = initialState,
  action: DataDepreciationAction
): DataDepreciationState {
  switch (action.type) {
    case FETCH_DATA_DEPRECIATION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_DEPRECIATION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataDepreciation: action.payload.dataDepreciation,
      };
    case RENEW_DATA_DEPRECIATION:
      return initialState;
    default:
      return state;
  }
}
