import {
  TransitionAfterIntroductionBaseFavorite,
  TransitionAfterIntroductionFavorite,
} from './types';

export const migrateFavorite = (
  deprecatedFavorite: TransitionAfterIntroductionBaseFavorite
): TransitionAfterIntroductionFavorite => {
  // 型を明示的に指定
  // 配列selectedCurrentFieldCodesが存在している場合、そのまま返却する
  if (!deprecatedFavorite.selectedCurrentFieldCode) {
    return deprecatedFavorite;
  }
  // 旧selectedCurrentFieldCodeを配列に変換して新しいオブジェクトselectedCurrentFieldCodesにマイグレーション
  return {
    ...deprecatedFavorite,
    selectedCurrentFieldCodes: Array.isArray(
      deprecatedFavorite.selectedCurrentFieldCode
    )
      ? deprecatedFavorite.selectedCurrentFieldCode // すでに配列の場合はそのまま使う
      : [deprecatedFavorite.selectedCurrentFieldCode], // 文字列の場合は配列に変換
  };
};
