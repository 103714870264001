import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC, lazy } from 'react';

const ErrorAlert: FC = () => {
  return (
    <Box sx={{ m: 2 }}>
      <Alert
        severity="error"
        sx={{ '& .MuiAlert-message': { flex: '1 1 auto' } }}
      >
        <AlertTitle>Error</AlertTitle>
        <Box>
          アプリケーションのアップデート、あるいは通信環境が悪いためファイルの読み込みに失敗しました。ブラウザをリロードしてください。
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Button
            color="error"
            variant="contained"
            onClick={() => window.location.reload()}
            sx={{ ml: 'auto' }}
          >
            リロード
          </Button>
        </Box>
      </Alert>
    </Box>
  );
};

/**
 * lazyでのpageをimport時にエラーを表示する
 */
export const lazyImportPage: typeof lazy = (factory) => {
  return lazy(() =>
    factory().catch((e: unknown) => {
      console.error(e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return Promise.resolve({ default: ErrorAlert as any });
    })
  );
};
