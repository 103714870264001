import { createSelector } from 'reselect';

import { SettingsOptionsDepreciationResponse } from '../../domain/depreciation/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_SETTINGS_OPTIONS_DEPRECIATION = 'FETCH_SETTINGS_OPTIONS_DEPRECIATION' as const;
const FETCH_SETTINGS_OPTIONS_DEPRECIATION_REQUEST = 'FETCH_SETTINGS_OPTIONS_DEPRECIATION_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_DEPRECIATION_SUCCESS = 'FETCH_SETTINGS_OPTIONS_DEPRECIATION_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_DEPRECIATION = 'RENEW_SETTINGS_OPTIONS_DEPRECIATION' as const;

export const SettingsOptionsDepreciationActionTypes = {
  FETCH_SETTINGS_OPTIONS_DEPRECIATION,
  FETCH_SETTINGS_OPTIONS_DEPRECIATION_REQUEST,
  FETCH_SETTINGS_OPTIONS_DEPRECIATION_SUCCESS,
  RENEW_SETTINGS_OPTIONS_DEPRECIATION,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchSettingsOptionsDepreciationAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DEPRECIATION,
  };
}

function fetchSettingsOptionsDepreciationRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_DEPRECIATION_REQUEST,
  };
}

function fetchSettingsOptionsDepreciationSuccessAction(
  settingsOptionsDepreciation: SettingsOptionsDepreciationResponse
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_DEPRECIATION_SUCCESS,
    payload: { settingsOptionsDepreciation },
  };
}

function renewSettingsOptionsDepreciationAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_DEPRECIATION,
  };
}

export const SettingsOptionsDepreciationActionCreators = {
  fetchSettingsOptionsDepreciationAction,
  fetchSettingsOptionsDepreciationRequestAction,
  fetchSettingsOptionsDepreciationSuccessAction,
  renewSettingsOptionsDepreciationAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type FetchSettingsOptionsDepreciationAction = ReturnType<
  typeof fetchSettingsOptionsDepreciationAction
>;
type FetchSettingsOptionsDepreciationRequestAction = ReturnType<
  typeof fetchSettingsOptionsDepreciationRequestAction
>;
type FetchSettingsOptionsDepreciationSuccessAction = ReturnType<
  typeof fetchSettingsOptionsDepreciationSuccessAction
>;
type RenewSettingsOptionsDepreciationAction = ReturnType<
  typeof renewSettingsOptionsDepreciationAction
>;

type SettingsOptionsDepreciationAction =
  | FetchSettingsOptionsDepreciationAction
  | FetchSettingsOptionsDepreciationRequestAction
  | FetchSettingsOptionsDepreciationSuccessAction
  | RenewSettingsOptionsDepreciationAction;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsDepreciationState = {
  loadingState: LoadingState;
  settingsOptionsDepreciation?: SettingsOptionsDepreciationResponse;
};

const initialState: SettingsOptionsDepreciationState = {
  loadingState: 'prepare',
  settingsOptionsDepreciation: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * 機種別償却 全ての状態を取得する
 */
function settingsOptionsDepreciationSelector(state: RootState) {
  return state.settingsOptionsDepreciation;
}

/**
 * 機種別償却 データのローディング状態を取得する
 * @returns ローディング状態
 */
export const settingsOptionsDepreciationLoadingStateSelector = createSelector(
  settingsOptionsDepreciationSelector,
  ({ loadingState }) => loadingState
);

/**
 * 機種別償却 データを取得する
 *
 */
export const settingsOptionsDepreciationDataSelector = createSelector(
  settingsOptionsDepreciationSelector,
  ({ settingsOptionsDepreciation }) => settingsOptionsDepreciation
);

/**
 * 機種別償却 searchConditionを取得する
 */
export const settingsOptionsDepreciationSearchConditionSelector = createSelector(
  settingsOptionsDepreciationSelector,
  ({ settingsOptionsDepreciation }) => {
    return settingsOptionsDepreciation?.searchCondition;
  }
);

/**
 * 機種別償却 fieldsを取得する
 */
export const settingsOptionsDepreciationFieldsSelector = createSelector(
  settingsOptionsDepreciationSelector,
  ({ settingsOptionsDepreciation }) => {
    return settingsOptionsDepreciation?.fields;
  }
);

/**
 * 機種別償却 kiTagsListを取得する
 */
export const settingsOptionsDepreciationKiTagListSelector = createSelector(
  settingsOptionsDepreciationSelector,
  ({ settingsOptionsDepreciation }) => {
    return settingsOptionsDepreciation?.searchCondition.kiTagList;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsDepreciationReducer(
  state = initialState,
  action: SettingsOptionsDepreciationAction
): SettingsOptionsDepreciationState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_DEPRECIATION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_DEPRECIATION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsDepreciation: action.payload.settingsOptionsDepreciation,
      };
    case RENEW_SETTINGS_OPTIONS_DEPRECIATION:
      return initialState;
    default:
      return state;
  }
}
