import { select } from 'redux-saga/effects';

import { RootState } from '../store';

/**
 * カスタムセレクト関数、redux-saga/effectsのselectを置き換えて利用をする。
 * yield selectでは型推論が効かないため、型推論が効くようにカスタムセレクト関数を作成。
 *
 * 利用方法：
 * const hoge = yield select(selector)を const hoge = yield* customSelect(selector)に置き換えるだけで利用が可能。
 *
 * 導入の経緯：
 * https://www.notion.so/Redux-yield-select-const-4c6ac5108a334011b22aef2b8985720d
 *
 * @template TSelected - 選択された状態の型
 * @param {function(RootState): TSelected} selector - 状態から選択するための関数
 * @returns {Generator<any, TSelected, TSelected>} - 選択された状態を生成するジェネレータ
 */
export function* customSelect<TSelected>(
  selector: (state: RootState) => TSelected
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<any, TSelected, TSelected> {
  return yield select(selector);
}
