import { sortNumber } from '../../utils/sort/sortNumber';
import { sortNumberOrString } from '../../utils/sort/sortNumberOrString';
import { sortNumberWithPrefix } from '../../utils/sort/sortNumberWithPrefix';
import { sortString } from '../../utils/sort/sortString';
import { OrderType, SortRows } from '../schemas';
import { DataDepreciationResponse } from './types';

let sortRows: SortRows[];

export const sortDepreciation = (
  dataDepreciation: DataDepreciationResponse,
  order: OrderType,
  sort: string
): DataDepreciationResponse => {
  if (!dataDepreciation) {
    return dataDepreciation;
  }
  // sortの文字列とdata?.data.columnsのcodeが一致するindexを取得
  const sortIndex = dataDepreciation?.data.columns.findIndex((column) => {
    return column.code === sort;
  });

  // sortの文字列とdata?.data.columnsのcodeが一致するtypeを取得
  const sortType = dataDepreciation?.data.columns.find((column) => {
    return column.code === sort;
  });

  if (sortIndex == null || sortType == null) {
    return dataDepreciation;
  }

  switch (sortType.type) {
    case 'string':
      sortRows = sortString(dataDepreciation.data.rows, sortIndex, order);
      break;
    case 'number':
      sortRows = sortNumber(dataDepreciation.data.rows, sortIndex, order);
      break;
    case 'numberWithPrefix':
      sortRows = sortNumberWithPrefix(
        dataDepreciation.data.rows,
        sortIndex,
        order
      );
      break;
    case 'numberOrString':
      sortRows = sortNumberOrString(
        dataDepreciation.data.rows,
        sortIndex,
        order
      );
      break;
    default:
      return dataDepreciation;
  }

  return {
    ...dataDepreciation,
    // rowsをsortedRowsに変更
    data: {
      ...dataDepreciation.data,
      rows: sortRows,
    },
  };
};
