import { createSelector } from 'reselect';

import { SettingsOptionsKiSyokenResponse } from '../../domain/kiSyoken/types';
import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_SETTINGS_OPTIONS_KISYOKEN = 'FETCH_SETTINGS_OPTIONS_KISYOKEN' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST = 'FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS = 'FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_KISYOKEN = 'RENEW_SETTINGS_OPTIONS_KISYOKEN' as const;

export const SettingsOptionsKiSyokenActionTypes = {
  FETCH_SETTINGS_OPTIONS_KISYOKEN,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
  RENEW_SETTINGS_OPTIONS_KISYOKEN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchSettingsOptionsKiSyokenAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN,
  };
}

function fetchSettingsOptionsKiSyokenRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  };
}

function fetchSettingsOptionsKiSyokenSuccessAction(
  settingsOptionsKiSyoken: SettingsOptionsKiSyokenResponse
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
    payload: { settingsOptionsKiSyoken },
  };
}

function renewSettingsOptionsKiSyokenAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_KISYOKEN,
  };
}

export const SettingsOptionsKiSyokenActionCreators = {
  fetchSettingsOptionsKiSyokenAction,
  fetchSettingsOptionsKiSyokenRequestAction,
  fetchSettingsOptionsKiSyokenSuccessAction,
  renewSettingsOptionsKiSyokenAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type FetchSettingsOptionsKiSyokenAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenAction
>;
type FetchSettingsOptionsKiSyokenRequestAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenRequestAction
>;
type FetchSettingsOptionsKiSyokenSuccessAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenSuccessAction
>;
type RenewSettingsOptionsKiSyokenAction = ReturnType<
  typeof renewSettingsOptionsKiSyokenAction
>;

type SettingsOptionsKiSyokenAction =
  | FetchSettingsOptionsKiSyokenAction
  | FetchSettingsOptionsKiSyokenRequestAction
  | FetchSettingsOptionsKiSyokenSuccessAction
  | RenewSettingsOptionsKiSyokenAction;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsKiSyokenState = {
  loadingState: LoadingState;
  settingsOptionsKiSyoken?: SettingsOptionsKiSyokenResponse;
};

const initialState: SettingsOptionsKiSyokenState = {
  loadingState: 'prepare',
  settingsOptionsKiSyoken: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] settingsOptionsの全ての状態を取得する
 */
function settingsOptionsKiSyokenSelector(state: RootState) {
  return state.settingsOptionsKiSyoken;
}

/**
 * [機種別商圏] settingsOptionsのローディング状態を取得する
 */
export const settingsOptionsKiSyokenLoadingStateSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] settingsOptionsのデータを取得する
 */
export const settingsOptionsKiSyokenSearchConditionSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => {
    // return settingsOptionsKiSyoken?.searchCondition

    return {
      halls: [
        { code: '23999193', name: 'デモホールB' },
        { code: '23999194', name: 'デモホールC' },
      ],
      makers: [
        { code: '0001', name: '平和' },
        { code: '0002', name: 'SANKYO' },
      ],
      kiList: [
        { code: '025909', name: 'PストリートファイターＶ　Ｋ.Ｏ.ＲＵＳＨ FMW' },
        { code: '025924', name: 'Pギルティクラウン２ ME' },
      ],
      sisTypes: [
        { code: '01', name: 'ハイ', shuCode: '01', viewOrder: 1 },
        { code: '02', name: 'ハイミドル', shuCode: '01', viewOrder: 2 },
        { code: '21', name: 'ノーマル', shuCode: '03', viewOrder: 3 },
      ],
      shuGroupList: [
        { id: '3', name: '4円Pグループ', shuList: ['P_4.00'] },
        { id: '4', name: '1円Pグループ', shuList: ['P_1.00'] },
        { id: '5', name: '20円Gグループ', shuList: ['S_20.00'] },
        { id: '6', name: '5円Gグループ', shuList: ['S_5.00'] },
      ],
      shuList: ['P_4.00', 'P_1.00', 'S_20.00', 'S_5.00'],
      latestBaseDateForWeeklySisData: '',
      latestBaseDateForMonthlySisData: '',
      kiTagList: [],
    } as SettingsOptionsKiSyokenResponse['searchCondition'] | undefined;
  }
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsKiSyokenReducer(
  state = initialState,
  action: SettingsOptionsKiSyokenAction
): SettingsOptionsKiSyokenState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsKiSyoken: action.payload.settingsOptionsKiSyoken,
      };
    case RENEW_SETTINGS_OPTIONS_KISYOKEN:
      return initialState;
    default:
      return state;
  }
}
