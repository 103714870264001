import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { shallowEqual, useSelector } from 'react-redux';

import { useHeaderState } from '../../../contexts/HeaderContext';

import { favoritesSelector } from '../../../redux/ui/settingsFavorites';
import { Logo } from '../../atoms/Icon/Logo';
import { GlobalFavoritesNavigation } from './GlobalFavoritesNavigation';
import { GlobalNavigationItem } from './GlobalNavigationItem';
import { GlobalNavigationSettingItem } from './GlobalNavigationSettingItem';

export const GlobalNavigation = () => {
  const favorite = useSelector(favoritesSelector, shallowEqual);
  const { isOpenMenu } = useHeaderState();

  return (
    <Box>
      <Typography
        variant="h5"
        component="h1"
        noWrap
        sx={{ m: 2, mb: 0, '& > svg': { height: '40px', width: 'auto' } }}
      >
        <Logo />
      </Typography>

      <List>
        <GlobalNavigationItem
          name="ホーム"
          href="/"
          hasFavorite={false}
          decorationIcon={{ prdReleaseDate: '2024-10-09' }}
        />
        <GlobalNavigationItem
          name="チェーン店レポート"
          href="/report/chain"
          decorationIcon={{ prdReleaseDate: '2024-10-23' }} // 計画データ表示項目追加
        />
        <GlobalNavigationItem
          name="店舗レポート"
          href="/report/hall"
          decorationIcon={{ prdReleaseDate: '2024-10-23' }} // 計画データ表示項目追加
        />
        <GlobalNavigationItem
          name="予定進捗推移"
          href="/report/planProgressTransition"
          decorationIcon={{ prdReleaseDate: '2024-05-29' }} // 着地見込データ表示対応
        />
        <GlobalNavigationItem
          name="PPMシェア一覧"
          href="/report/ppmShare"
          decorationIcon={{ prdReleaseDate: '2024-08-21' }} // 検索結果共有機能追加
        />
        <GlobalNavigationItem
          name="機種別償却"
          href="/report/depreciation"
          hasFavorite={false}
          decorationIcon={{ type: 'new', prdReleaseDate: '2024-12-18' }} // 画面追加
        />
        <GlobalNavigationItem
          name="機種集計"
          href="/report/model"
          decorationIcon={{ prdReleaseDate: '2024-11-27' }} // 表示項目「最終スタート」「最終ゲーム」
        />
        <GlobalNavigationItem
          name="SIS機種レポート"
          href="/report/sis"
          decorationIcon={{ prdReleaseDate: '2024-11-13' }} // データ表示期間の拡張を実装
        />
        <GlobalNavigationItem
          name="台別データ一覧"
          href="/report/dai"
          decorationIcon={{ prdReleaseDate: '2024-11-27' }} // 表示項目「最終スタート」「最終ゲーム」
        />
        <GlobalNavigationItem
          name="モード別集計"
          href="/report/mode"
          decorationIcon={{ prdReleaseDate: '2024-09-04' }} // 検索結果共有機能追加
        />
        <GlobalNavigationItem
          name="導入後推移"
          href="/report/transitionAfterIntroduction"
          decorationIcon={{ prdReleaseDate: '2024-12-18' }} // 項目絞り込みの複数選択機能追加
        />
        <GlobalNavigationItem
          name="期間推移"
          href="/report/terminalTransition"
          decorationIcon={{ prdReleaseDate: '2024-07-23' }} // 検索結果共有機能追加
        />
        <GlobalNavigationItem
          name="機種別推移"
          href="/report/modelTransition"
          decorationIcon={{ prdReleaseDate: '2024-08-21' }} // 中古機価格の推移グラフ項目追加
        />

        {/*  システム設定 */}
        <GlobalNavigationSettingItem />

        {/*  お気に入り */}
        <GlobalFavoritesNavigation
          favoriteList={favorite?.favorites}
          isOpenMenu={isOpenMenu}
        />
      </List>
    </Box>
  );
};
