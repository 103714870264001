import * as Sentry from '@sentry/browser';
import { put } from 'redux-saga/effects';

import { isClarisApiError } from '../domain/error';

import { ErrorActionCreators } from '../redux/ui/error';

/**
 * Sagaで発生するAPIのエラー以外を補足する
 *
 * APIで発生するエラーについては、すべてaxiosのinterceptで行う
 */
export function* handleErrorSaga(error: unknown) {
  if (isClarisApiError(error) && error.response) {
    return;
  }
  console.error(error);
  const message = error instanceof Error ? error.message : 'unknown error';

  Sentry.captureException(error, {
    tags: {
      errorMessage: message,
    },
  });

  yield put(ErrorActionCreators.setError(message));
}
