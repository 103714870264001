import { OrderType, SortRows } from '../../domain/schemas';

/**
 * typeがsortNumberOrStringの場合のソート処理
 * @param rows ソート対象のrows
 * @param index ソート対象の列のindex
 * @param order ソート条件
 */
export const sortNumberOrString = (
  rows: SortRows[],
  index: number,
  order: OrderType
) => {
  const compareNumbers = (a: SortRows, b: SortRows, order: OrderType) => {
    const valueA = a.data.at(index)?.value;
    const valueB = b.data.at(index)?.value;

    if (valueA == null || valueB == null) {
      return 0;
    }

    // ＊が含まれている場合は元のデータを返す
    if (valueA === '*' || valueB === '*') {
      return 0;
    }

    // queryParameter.kiList(他の場合も)がnullの場合は一番上に配置(平均合計行の場合はnullなので一番上に持ってくる)
    if (
      a.queryParameter.kiList === null ||
      a.queryParameter.sisTypes === null ||
      a.queryParameter.makers === null ||
      a.queryParameter.halls === null
    ) {
      return -1;
    }
    if (
      b.queryParameter.kiList === null ||
      b.queryParameter.sisTypes === null ||
      b.queryParameter.makers === null ||
      b.queryParameter.halls === null
    ) {
      return 1;
    }

    // ハイフンを後ろにするための条件
    if (valueA === '-') return 1;
    if (valueB === '-') return -1;

    // カンマとパーセント記号が含まれる場合は削除して数値に変換
    const numA = Number(valueA.replace(/,/g, '').replace(/%/g, ''));
    const numB = Number(valueB.replace(/,/g, '').replace(/%/g, ''));

    if (isNaN(numA) || isNaN(numB)) {
      return 0;
    }

    return order === 'asc' ? numA - numB : numB - numA;
  };

  return rows.slice().sort((a, b) => compareNumbers(a, b, order));
};
